import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { InterLink } from "../components/menulinks/MenuLinks"

const Page404 = () => (
  <Layout>
    <SEO title="404: nem található az oldal" />

    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div />
          <ol>
            <li>
              <InterLink to={`/`}>Főoldal</InterLink>
            </li>
            <li>404 hiba</li>
          </ol>
        </div>
      </div>
    </section>
    <section className="">
      <div className="container">
        <div
          style={{
            paddingTop: "3rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: "#bbbbbb",
          }}
        >
          <h3>404: Nincs ilyen oldal</h3>
          <div>
            <InterLink to={"/"}>
              <i className="icofont-arrow-left" /> Vissza a főoldalra
            </InterLink>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
export default Page404
